import bapi from "../services/Bapi";
import React, { useState } from "react";
import cookie from 'react-cookies';
import '../components/licence-plate.scss';

const Start = ({ onCompletion, showModal, setManualInput, showKB, isKioskMode }) => {
	const cookieLP = isKioskMode ? null : cookie.load('licence-plate');
	const [loading, setLoading] = useState(false);
	const [licensePlate, setLicensePlate] = useState(cookieLP ? cookieLP : "");

	const getLicensePlateLen = () => {
		return licensePlate.replace(/-/g, '').length;
	}

	const getCarData = () => {
		if (loading || getLicensePlateLen() !== 6) return false;
		setLoading(true);
		bapi.getData(licensePlate).then(res => {
			if (res.error === 1) {
				showModal('error-licenceplate-unknown');
			}
			else {
				if (res.msg === 'success') {
					const oems = bapi.getOems();
					const custom = bapi.getCustom();
					setManualInput(false);
					onCompletion(3, {
						make: res.data.CarMake,
						serie: res.data.CarSerie,
						type: res.data.CarType,
						tyres: { oems: oems, custom: custom },
						ids: {
							make: res.data.make,
							model: res.data.model,
							serie: res.data.serie,
							year: res.data.year
						}
					});
				} else if (res.msg === 'carmakenotfound') {
					console.log('error', res.data);
					setManualInput(true);
					onCompletion(2, res.data);
				}
				cookie.save('licence-plate', licensePlate, {
					maxAge: 60 * 60 * 24 * 365 // 1 year
				});
			}
			setLoading(false);
		});
	}

	const inputOnKeyUp = e => {
		if (e.defaultPrevented) {
			return;
		}
		var key = e.key || e.keyCode;
		if (key === 'Enter' || key === 13) {
			if (!loading && getLicensePlateLen() === 6) {
				getCarData();
			}
		}
	}

	return <React.Fragment>
		<div id="intro">
			<h2>Voer uw kenteken in</h2>
			<div className="licence-plate">
				<img alt="flag" src="/images/flag-licence-plate.svg" />
				<input type="text" placeholder="01-ABC-1" autoComplete="on" spellCheck="false" maxLength="8" onChange={e => setLicensePlate(e.target.value)} onKeyUp={inputOnKeyUp} onFocus={e => { e.target.value = ""; e.target.placeholder = ""; if (isKioskMode) showKB(true)}} value={licensePlate} />
			</div>
			<button disabled={loading || getLicensePlateLen() !== 6} data-loading={loading} className="chunky" onClick={getCarData}>Volgende</button>
		</div>
	</React.Fragment>
}

export default Start;