import React, { useEffect, useRef } from "react";

const ModalInfoKiosk = () => {

	return <React.Fragment>
		<h2>Over deze applicatie</h2>
		<p>
			Deze applicatie wordt u aangeboden door het programma Kies de Beste Band.<br />
			De applicatie is ontwikkeld door Schuttelaar&nbsp;&amp;&nbsp;Partners en maakt gebruik van de bandendatabase van Apollo Vredestein.
		</p>

        <p>Controleer uw bandenspanning bij voorkeur wanneer de banden koud zijn.</p>

        <p>De bandenspanningsgegevens in de database van Apollo Vredestein worden berekend volgens de richtlijnen van de Europese organisatie ETRTO (European Tyre Rim and Technical Organisation). De gebruikte algoritmes houden o.a. rekening met beladings- en snelheidsindex en maat van de banden. </p>

		<p>Ondanks dat de bandenspanning op de best mogelijke manier via de richtlijnen van ETRTO wordt berekend, kunnen er geringe afwijkingen ontstaan ten opzichte van de door de autofabrikant vermelde waarden in het instructieboekje of op de sticker op de deurstijl of binnenkant tankklepje van de auto. Deze bandenspanningsapp moet worden gezien als een aanvulling in het geval dat deze niet meer aanwezig zijn of als banden met een afwijkende maat zijn gemonteerd waarvan de bandenspanningsgegevens ontbreken. </p>

		<p>Hoewel alle mogelijke zorg is besteed aan de inhoud van deze applicatie en het achterliggende databestand, zijn Kies de Beste Band, Apollo Vredestein BV en andere betrokkenen niet aansprakelijk voor eventuele gevolgen als gevolg van het gebruik ervan. </p>

        <p>Heeft u suggesties voor deze applicatie? Neem dan contact op met redactie@kiesdebesteband.nl.</p>

	</React.Fragment>
}

export default ModalInfoKiosk;