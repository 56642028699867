import bapi from './services/Bapi';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import Start from './steps/Start';
import ManualInput from './steps/ManualInput';
import CarSpecs from './steps/CarSpecs';
import Results from './steps/Results';
import Modal from './components/Modal';

import './App.scss';

class App extends Component {


  isKioskMode = false;
  steps = {};
  carData;
  rootEl = document.getElementById('root');
  activeInput = null;

  slides = {
    1: 'start',
    2: 'manual input',
    3: 'tyre selection',
    4: 'result'
  }


  constructor(props) {

    super(props);
    this.introMarginTop = 0;
    this.refModal = React.createRef();
    this.refKb = React.createRef();

    this.pageNames = [
      'start',
      'results'
    ];

    this.state = {
      showIntro: 1,
      activePage: 0,
      manualInput: false,
      isBigScreen: false,
      kbShow: false
    };
  }


  kbOnChangeAll = (inputs) => {
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
    nativeInputValueSetter.call(this.activeInput, inputs.default);

    var ev2 = new Event('input', { bubbles: true });
    this.activeInput.dispatchEvent(ev2);

/*     this.activeInput.value = inputs.default;
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("change", false, true);
    this.activeInput.dispatchEvent(evt);
 */
  }

  kbOnKeyPress = (key) => {
    const el = this.activeInput;
  }

  kbSetActiveInput = (el) => {
    this.activeInput = el;
    this.activeInput.blur();
  }

  showKB = (value) => {
    if (value === true) {
      this.kbSetActiveInput(document.activeElement);
      this.keyboard.setInput('');
      this.onClickOutside(this.refKb, () => this.showKB(false));
    }
    this.setState({ kbShow: value }, () => {
      if (this.state.activePage !== 1) return;

      let wrapper = document.getElementById("wrapper");
      if (value === true) {
        wrapper.style.marginTop = 'calc(((100% - 7.5rem) * .45) - 11rem)';
      } else {
        wrapper.style.marginTop = 'auto';
      }
    });
  }

  getRequestParam(param) {
    const params = new URLSearchParams(window.location.search);
    return params.has(param) ? params.get(param) : null;
  }

  componentDidMount() {
    window.onpopstate = e => {
      if (e.state && typeof e.state === 'object') {
          this.renderStep(e.state.step, e.state.data, false);
      }
    }
    this.setWrapperHeight();

    window.addEventListener('resize', () => {
      this.setWrapperHeight();
      this.setScreenSize();
    });

    if (this.getRequestParam('kioskMode') !== null) {
      this.isKioskMode = true;
      document.querySelector('.btn-back').onclick = (e) => {
        window.history.back();
      };
    }

    this.setScreenSize();

    this.renderStep(1);
  }

  setScreenSize() {
    if (this.rootEl.offsetWidth >= 640) {
      this.setState({ isBigScreen: true });
    } else {
      this.setState({ isBigScreen: false });
    }
  }

  setWrapperHeight() {
    const root = document.getElementById('root');
    const viewport = document.querySelector(".main");
    if (viewport.getAttribute('data-show-intro') !== "1") {
      return;
    }
    const wrapper = document.getElementById("wrapper");
    const slideWrapper = wrapper.querySelector('.slideWrapper');


    const vpHeight = viewport.offsetHeight;
    const vpWidth = root.offsetWidth;

    slideWrapper.style.height = 'auto';
    slideWrapper.style.transition = 'none';

    wrapper.style.height = `calc((${vpHeight}px - ((${vpWidth}px - 10rem) * 1.16)) - 8rem)`;
  }

  start = activePage => {
    let wrapper = document.getElementById("wrapper");
    this.introMarginTop = wrapper.offsetTop;
    this.setState({
      showIntro: 0,
    }, () => {
      this.animateMarginTop(activePage);
    });
  }

  animateMarginTop = toPage => {
    let main = document.querySelector('.main');
    let wrapper = document.getElementById("wrapper");
    let content = document.querySelector('#wrapper .content');
    let slideWrapper = wrapper.querySelector('.slideWrapper');
    let slidesWrapper = slideWrapper.querySelectorAll('.slide > .wrapper');
    let intro = slideWrapper.querySelector('#intro');
    let animWrapper = slideWrapper.querySelectorAll('.slide:not(:nth-of-type(1))');

    wrapper.style.height = 'auto';
    wrapper.style.marginTop = this.introMarginTop + 'px';

    slideWrapper.style.height = slideWrapper.clientHeight + 'px';
    slidesWrapper.forEach((el) => { el.style.overflow = 'hidden'});

    setTimeout(() => {
      main.classList.add('transforming');
      if (this.isKioskMode) {
        wrapper.style.marginTop = 'calc(((100% - 7.5rem) * .45) - 7rem)';
      } else if (this.rootEl.clientHeight >= 568) {
        wrapper.style.marginTop = 'calc((100% - 7.5rem) * .45)';
      } else {
        wrapper.style.marginTop = 0;
      }

      intro.style.opacity = 0;
      animWrapper.forEach((el) => {
        el.style.position = 'relative';
        el.style.opacity = 1;
        el.style.height = '100%';
      })


      //return null;
      setTimeout(() => {
        intro.style.display = 'none';
        main.classList.remove('transforming');
        main.classList.add('alt');

        slideWrapper.style.height = '100%';
        content.style.height = '100%';
        //slideWrapper.style.height = 'calc(' + (main.clientHeight - (slideWrapper.getBoundingClientRect().top - main.getBoundingClientRect().top)) + 'px - 5.25rem)';

        this.setState({
          activePage: toPage || 2,
          kbShow: false
        }, () => {
          setTimeout(() => {
            slideWrapper.style.transition = 'transform 0.5s ease-in-out, height 0.3s ease-in';
            // clean up
            setTimeout(() => {
              slidesWrapper.forEach((el) => { el.style.overflow = 'auto' });
              intro.style.removeProperty('display');
              intro.style.removeProperty('opacity');
            }, 300);
          }, 200);
        });

      }, 600);
    }, 100);
  }

  showResults = pressures => {
    this.renderStep(4, {pressures: pressures, carData: this.carData});
  }

  goToSlide = (slide) => {
    if (this.state.activePage === 1 && this.state.showIntro) {
      this.start(slide);
    } else {
      let newState = {activePage: slide};
      if (slide === 1) {
        newState.showIntro = 1;
        this.setState(newState, () => {
          const wrapper = document.getElementById("wrapper");
          const slideWrapper = wrapper.querySelector('.slideWrapper');
          const animWrapper = slideWrapper.querySelectorAll('.slide:not(:nth-of-type(1))');
          const content = wrapper.querySelector('.content');


          document.querySelector('.main').classList.remove('alt');

          animWrapper.forEach((el) => {
            el.style.opacity = 0;
            el.style.position = 'absolute';
          });
          wrapper.style.marginTop = 'auto';
          content.style.height = 'auto';

          this.setWrapperHeight();
        });

      } else {
        this.setState(newState);
      }
    }

    let docTitle = this.slides[slide];
    if (this.isKioskMode) {
      docTitle += ' [kiosk mode]';
    }
    window.tracker.trackPageView({
      documentTitle: docTitle, // optional
    });

    //window._paq.push(['trackEvent', 'general', 'navigate', this.slides[slide]], slide);

  }

  showModal = (modalId) => {
    if (!this.refModal.current) return false;
    this.refModal.current.show(modalId);
    window.tracker.trackEvent({
      category: 'popup',
      action: 'show',
      name: modalId
    });
  }
  hideModal = () => {
    if (!this.refModal.current) return false;
    this.refModal.current.hide();
  }

  onClickOutside = (ref, handler) => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);

      // now need to manually trigger the intended event if user clicked on the "volgende" button
      event.target.click();
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }

  setManualInput = (state) => {
    this.setState({ manualInput : state});
  }

  renderStep = (stepId, data, push) => {
    //console.log('render', stepId, data);
    if (push !== false) {
      //console.log('pushstate data', data);
      window.history.pushState({ step: stepId, data: data }, stepId);
    }
    switch(stepId) {
      case 1:
      default:
        ReactDOM.render(
          <Start
            onCompletion={this.renderStep}
            showModal={this.showModal}
            showKB={this.showKB}
            setManualInput={this.setManualInput}
            isKioskMode={this.isKioskMode}/>,
          this.steps[1], () => this.goToSlide(1)
        );
      break;
      case 2:
        this.setManualInput(true);
        bapi.getBrands().then(
          (makes) => {
            ReactDOM.render( // always rerender by setting unique key
              <ManualInput
                key={new Date().getTime()}
                makes={makes}
                make={data.make}
                model={data.model}
                serie={data.serie}
                type={data.type}
                year={data.year}
                onCompletion={selection => this.getCarDataManual(selection)}
                isKioskMode={this.isKioskMode} />,
              this.steps[2], () => this.goToSlide(2)
            );
          }
        );
      break;
      case 3:
        this.carData = {
          make: data.make,
          serie: data.serie,
          type: data.type
        }
        ReactDOM.render(
          <CarSpecs
            data={data}
            onCompletion={pressures => this.showResults(pressures)}
            showModal={this.showModal}
            changeSelection={ids => this.renderStep(2, ids)}
            isKioskMode={this.isKioskMode} />,
          this.steps[3], () => this.goToSlide(3)
        );
      break;
      case 4:
        ReactDOM.render(
          <Results data={data} showModal={this.showModal} />,
          this.steps[4], () => this.goToSlide(4)
        );
      break;
    }

  }

  getCarDataManual = (selection) => {
    return bapi.getCarDataManual(selection.ids).then(res => {

      if (res.msg === 'success') {
        const oems = bapi.getOems();
        const custom = bapi.getCustom();

        console.log('succes', res);

        this.renderStep(3, {
          make: selection.human.make.label,
          serie: selection.human.serie.label,
          type: selection.human.type.label,
          tyres: { oems: oems, custom: custom },
          ids: selection.ids
        });
        return 'success';
      } else {
        console.log('return error');
        return 'error';
      }
    });
  }

  getActivePageName = () => {
    return this.pageNames[this.state.activePage - 1];
  }

  render() {
    var backButton = null;
    /*if (this.state.activePage > 1) {
      backButton = <div className="back-button" onClick={() => this.goToSlide(1)}></div>;
    }*/

    return (
      <div data-kioskmode={this.isKioskMode ? 1 : 0} data-kbshow={this.state.kbShow} data-bigscreen={this.state.isBigScreen ? 1 : 0} className="wrapper" data-active={this.state.activePage}>
        <div className="btn-back"></div>
        <div className="wrapper">
        <div data-show-intro={this.state.showIntro} data-active={this.state.activePage} className="main" id={"page-" + this.getActivePageName()}>

          {backButton}

          <div id="wrapper">
            <div className="content">
              <div className="slideWrapper">
                <div className="slide" id="start" ref={c => (this.steps[1] = c)}></div>
                <div className="slide" id="manual-input" ref={c => (this.steps[2] = c)}></div>
                <div className="slide" id="car-specs" ref={c => (this.steps[3] = c)}></div>
                <div className="slide" id="results" ref={c => (this.steps[4] = c)}></div>
              </div>
            </div>
              {this.isKioskMode ?
                <footer>
                  <img alt="logo Vredestein" src="/images/logo-vredestein.svg?v=3" />
                </footer>
                :
                <footer>
                  <a href="https://www.vredestein.nl" rel="noopener noreferrer" target="_blank"><img alt="logo Vredestein" src="/images/logo-vredestein.svg?v=3" /></a>
                </footer>
              }
          </div>
        </div>
        </div>
        <footer>
          {!this.isKioskMode &&
          <a className="contact" href="mailto:tic@apollotyres.com">Contact</a>
          }
          <div className="disclaimer" onClick={() => this.showModal('info')}>Disclaimer</div>
          {/* <div className="btn-info" role="button" onClick={() => this.showModal('info')}></div> */}

        </footer>
        <Modal ref={this.refModal} isKioskMode={this.isKioskMode}></Modal>
        {this.isKioskMode &&
          <div className="keyboard" data-kbshow={this.state.kbShow} ref={this.refKb}
>
            <Keyboard
            keyboardRef={r => (this.keyboard = r)}
            onChangeAll={this.kbOnChangeAll}
            onKeyPress={this.kbOnKeyPress}
            layout={{
              'default': [
            '1 2 3 4 5 6 7 8 9 0 {bksp}',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M'
            ]
            }}
            />
          </div>
        }
      </div>
    );
  }
}

export default App;
