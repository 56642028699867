import bapi from "../services/Bapi";
import React, { useState, useEffect } from "react";
import Switch from '../components/Switch';
import Select from '../components/Select';
import './car-specs.scss';

const CarSpecs = ({ data, onCompletion, showModal, changeSelection, isKioskMode}) => {

	//console.log('changeSel', changeSelection, data.ids);
	const [make, setMake] = useState();
	const [serie, setSerie] = useState();
	const [type, setType] = useState();
	const [tyres, setTyres] = useState();
	//const [type, setType] = useState(1); // 1 = oem, 2 = custom
	const [warmed, setWarmed] = useState(true);
	const [consideredWarmed, setConsideredWarmed] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reveal, setReveal] = useState(1000);
	const warmedCoeff = 0; // usually something like .3, currently disabled
	const tyreOptions = data.tyres;

	setTimeout(() => {
		setReveal(0);
	}, reveal);

	useEffect(() => {
		setMake(data.make);
		setSerie(data.serie);
		setType(data.type);
		setTyres(null);
		setWarmed(true);
	}, [data.make, data.serie, data.type])

	/*useEffect(() => {
		setTyres(null);
	}, [type]);*/

	/*useEffect(() => {
		if (tyreOptions.length === 1) {
			setTyres(parseInt(tyreOptions[0].value));
		}
	}, [tyreOptions, tyres]);*/

	const calculateAndProceed = () => {
		setConsideredWarmed(true);
		setLoading(true);
		calculatePressure().then(
			pressures => {
				setLoading(false);
				onCompletion(pressures);
			}
		)
	}

	const calculatePressure = () => {
		return bapi.getTyrePressures(tyres, warmed ? warmedCoeff : 0).then(
			res => {
				return res.data;
			}
		)
	}

	return <React.Fragment>
		<div className="wrapper">
		<section className="selection">
			<div className="car check" data-checked="1">
				<div className="description">Gevonden voertuig:</div>
				<h2>{make} {serie}</h2>
				<div onClick={() => {changeSelection(data.ids)}} className="subdued left clickable">Is dit niet uw voertuig?</div>
			</div>
			<div className="tyres check" data-checked={tyres ? 1 : 0} style={{ opacity: reveal === 0 ? 1 : 0 }}>
				<h2>Kies uw bandenmaat <div onClick={() => showModal('how-to-determine-size')} className="btn-info" role="button"></div></h2>

				<label className="tyre-size">
					<Select
						onChange={value => { setTyres(parseInt(value)) }}
						options={tyreOptions}
						groups={true}
						groupsLabels={['Standaard sets (voor / achter)', 'Afwijkende sets (voor / achter)']}
						emptyLabel={"Selecteer bandenmaat"}
						isKioskMode={isKioskMode}
					/>
				</label>
				<div onClick={() => showModal('info-identify-size')} className="subdued left clickable">Bandenmaat niet gevonden?</div>

			</div>
			{/* switch is currently disabled, may be used later on */}
			<div style={{display: 'none'}} className="warmed-check check" data-checked={consideredWarmed ? 1 : 0} data-invisible={!tyres || reveal !== 0}>
				<h2>Heeft u langer dan 15 minuten gereden?</h2>
				<Switch onChange={value => { setWarmed(value) }} />
			</div>
		</section>
	</div>
	<div className="buttons">
		<button disabled={!tyres} data-loading={loading} className="chunky" onClick={calculateAndProceed}>Volgende</button>
	</div>
	</React.Fragment>
}

export default CarSpecs;