import isIos from './isIos';
import { saveAs } from 'file-saver';
import * as ics from 'ics';

class IcsGenerator {
	constructor(eventData) {

		let startDate = new Date();
		startDate.setMonth(startDate.getMonth() + 2); // first reminder in 2 months
		let endDate = startDate;
		endDate.setDate(endDate.getDate() + 1); // add a day

		const event = {
			start: [startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()],
			end: [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()], // will create a one day event
			recurrenceRule: "FREQ=MONTHLY;INTERVAL=2", // for fixed number: COUNT=12
			busyStatus: 'FREE',
			title: 'Bandenspanningscheck',
			description: window.location.origin,
			//url: window.location.origin,
			status: 'CONFIRMED'
		}

		ics.createEvent(event, (error, value) => {
			if (error) {
				console.log(error)
				return
			}

			//var mime = 'text/x-vCalendar';
			var mime = 'text/calendar';
			var blob = new Blob([value], { type: `${mime};charset=${document.characterSet}`});


			if (isIos()) {
				window.open(`data:${mime};charset=${document.characterSet},${escape(value)}`);
			} else {
				saveAs(blob, "bandenspanning-herinneringen.ics");
			}


			console.log(value)
		})
	}
}

export default IcsGenerator;