import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import { ReactComponent as ResultSVG } from "../main-result.svg";
import IcsGenerator from '../services/IcsGenerator';
import './results.scss';

const Animation = styled.section`
	.balloon {
		animation: ${props => props.status === 'animate' ? `show` : `none`} .6s;

		&.balloon-1 {
			left: calc(${props => props.svgOffsets.wheelRear}px - 50%);
			animation-delay: 1.8s;
		}
		&.balloon-2 {
			left: calc(${props => props.svgOffsets.wheelFront}px - 50%);
			animation-delay: 1.2s;
		}
	}

	svg {
		#Group-7 {
			transform: translate3d(calc(100% + ${props => props.svgOffsets.car}px), 0, 0);
			animation: ${props => props.status === 'animate' ? `drive_in` : `none`} 1.2s;

			#Path-8 {
				opacity: 0;
				animation: ${props => props.status === 'animate' ? `flicker` : `none`} 15s;
				animation-delay: 4s;
				animation-fill-mode: forwards;
			}
		}
	}

	@keyframes drive_in {
		0% { transform: translate3d(calc(100% + ${props => props.svgOffsets.car}px), 0, 0);}
		100% {transform:  translate3d(${props => props.svgOffsets.car}px, 0, 0);}
	}
`;


const Results = ({ data, showModal }) => {
	const [reveal, setReveal] = useState();
	const [width, setWidth] = useState(null);
	const [svgOffsets, setSvgOffsets] = useState({ car: null, wheelFront: null, wheelRear: null });

	const el = useRef();
	const timeout = 600;

	useEffect(() => {
		if (el.current) {
			window.addEventListener('resize', () => {
				console.log('resize');
				const newWidth = el.current.getBoundingClientRect().width;
				setWidth(newWidth);
				setSvgOffsets(getSvgOffsets(el.current));
			});
		}
	}, [data]);

	useEffect(() => {
		if (width === null && typeof el.current !== 'undefined') {
			setWidth(el.current.getBoundingClientRect().width);
			setSvgOffsets(getSvgOffsets(el.current));
		}
	}, [width, svgOffsets]);

	useEffect(() => {
		if (el.current) setSvgOffsets(getSvgOffsets(el.current));
		setReveal(false);
		setTimeout(() => {
			setReveal(true);
		}, timeout);
	}, [data]);

	const getSvgOffsets = (svg) => {
		const ratio = svg.getBoundingClientRect().width / parseInt(svg.getAttribute('width'));
		const wheelRear = svg.querySelector('#Group-7 circle:nth-of-type(1)');
		const wheelFront = svg.querySelector('#Group-7 circle:nth-of-type(3)');
		return {
			car: svg.querySelector('#Group-7').transform.baseVal.consolidate().matrix.e,
			wheelRear: (wheelRear.cx.baseVal.value * ratio) + (wheelRear.getBoundingClientRect().width / 2.3),
			wheelFront: (wheelFront.cx.baseVal.value * ratio) + (wheelFront.getBoundingClientRect().width / 2.3)
		}
	}

	const generateIcs = () => {
		new IcsGenerator();
		window.tracker.trackEvent({
			category: 'action',
			action: 'add to calendar'
		});
	}

	return <div className="wrapper">
		<section>
			<header>
				<h2>Uw bandenspanning</h2>
				<p>{data.carData.make} {data.carData.type}</p>
			</header>

			{data.pressures && data.pressures.PressureFront && data.pressures.PressureFront != null ?
			<Animation id="result" status={reveal ? 'animate' : 'waiting'} width={width} svgOffsets={svgOffsets}>
				<ResultSVG ref={el} />
				<div id="balloons">
					<div className="wrapper">
						<div className="balloon balloon-1">{data.pressures.PressureRear.toLocaleString('nl_NL')} bar</div>
					</div>
					<div className="wrapper">
						<div className="balloon balloon-2">{data.pressures.PressureFront.toLocaleString('nl_NL')} bar</div>
					</div>
				</div>
				<div className="calendar"></div>
			</Animation>
			:
			<div className="message">
				Kon geen advies voor de bandenspanning vinden voor de geselecteerde banden.
			</div>
			}

			<div class="subdued"><br />De bandenspanning is op basis van volle belading. <br /><span class="clickable inverted"  onClick={() => showModal('results-more')}>Lees meer</span>.</div>

{/* 				<div onClick={() => showModal('how-to-pump')} className="description how-to" style={{ opacity: reveal ? 1 : 0 }}>Hoe kan ik mijn autobanden veilig oppompen? &rsaquo;</div>
 */}
				<div className={"monthly-reminder"} data-invisible={reveal ? 0 : 1} >
				<h2 className="text" onClick={generateIcs}>
					Plaats een 2 maandelijkse herinnering in uw agenda
				</h2>
			</div>
		</section>
	</div>
}

export default Results;