import React, { useEffect, useRef } from "react";

const ModalInfo = () => {

	const refOptout = useRef(null);

	useEffect(() => {
		window._paq.push([function () {
			if (document.cookie.indexOf('mtm_consent_removed') !== -1) {
				refOptout.current.checked = true;
				//setOptedOut(true);
			}
		}])
	}, [])

	function optInOut(e) {
		if (e.target.checked) {
			window._paq.push(['optUserOut']);
			document.cookie = "mtm_consent_removed=; expires=Fri, 31 Dec 9999 23:59:59 CET; path=/;"; // piwik should create this but it doesn't always do it, so we need to do it manually.
		} else {
			window._paq.push([function () {
				this.setConsentGiven(false); // need to explicitly set this, otherways it will act is if user has given explicit consent to track personally with cookies.
				this.forgetUserOptOut();
				document.cookie = "mtm_consent_removed=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // piwik should remove this but it doesn't, so we need to do it manually.
			}]);
		}
	}


	return <React.Fragment>
		<h2>Over deze applicatie</h2>
		<p>
			Deze applicatie wordt u aangeboden door <a href="https://www.vredestein.nl" target="_blank">Apollo Vredestein</a> en het programma <a href="http://www.kiesdebesteband.nl/" target="_blank">Kies de Beste Band</a>.<br />
			De applicatie is ontwikkeld door <a href="https://www.schuttelaar.nl/" target="_blank">Schuttelaar&nbsp;&amp;&nbsp;Partners</a> en maakt gebruik van de bandendatabase van <a href="https://www.vredestein.nl" target="_blank">Apollo Vredestein</a>.
		</p>

        <p>Controleer uw bandenspanning bij voorkeur wanneer de banden koud zijn.</p>

        <p>De bandenspanningsgegevens in de database van Apollo Vredestein worden berekend volgens de richtlijnen van de Europese organisatie ETRTO (European Tyre Rim and Technical Organisation). De gebruikte algoritmes houden o.a. rekening met beladings- en snelheidsindex en maat van de banden. </p>

		<p>Ondanks dat de bandenspanning op de best mogelijke manier via de richtlijnen van ETRTO wordt berekend, kunnen er geringe afwijkingen ontstaan ten opzichte van de door de autofabrikant vermelde waarden in het instructieboekje of op de sticker op de deurstijl of binnenkant tankklepje van de auto. Deze bandenspanningsapp moet worden gezien als een aanvulling in het geval dat deze niet meer aanwezig zijn of als banden met een afwijkende maat zijn gemonteerd waarvan de bandenspanningsgegevens ontbreken. </p>

		<p>Hoewel alle mogelijke zorg is besteed aan de inhoud van deze applicatie en het achterliggende databestand, zijn Kies de Beste Band, Apollo Vredestein BV en andere betrokkenen niet aansprakelijk voor eventuele gevolgen als gevolg van het gebruik ervan. </p>

        <p>Heeft u suggesties voor deze applicatie? Neem dan contact op met <a href="mailto:tic@apollotyres.com">tic@apollotyres.com</a>.</p>

		<h2>Anonieme data</h2>

		<p>
			U kunt ervoor kiezen om te voorkomen dat deze website de acties die u uitvoert kan analyseren. Hierdoor wordt uw privacy beschermd, maar wordt ook voorkomen dat de beheerder van de website van uw acties kan leren en een betere ervaring voor u en andere gebruikers kan creëren.

		</p>

		<label>
			<input ref={refOptout} type="checkbox" defaultChecked={false} onChange={optInOut}/> Verzamel geen informatie over mijn activiteiten op deze website.
		</label>

	</React.Fragment>
}

export default ModalInfo;
