function getYearsFromNow(amount) {
	let currentYear = new Date().getFullYear();
	let years = [];
	while (amount > 0) {
		years.push(currentYear);
		currentYear--;
		amount--;
	}
	return years;
}

export default {getYearsFromNow}