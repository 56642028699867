import React from 'react';

const ModalHowToPump = () => {
	return <React.Fragment>
		<h2>Hoe kan ik mijn autobanden veilig oppompen?</h2>
		<p>Bekijk in de korte video hoe je eenvoudig je autoband oppompt.</p>
		<div className="video-container">
			<iframe style={{ maxWidth: '100%' }} title="Hoe kan ik mijn autobanden veilig oppompen?" width="560" height="315" src="//www.youtube-nocookie.com/embed/MNikAOYYVzk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>
		</div>
	</React.Fragment>
}

export default ModalHowToPump;