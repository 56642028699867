import React, { useState, forwardRef, useImperativeHandle } from "react";
import ReactDOM from 'react-dom';
import ModalInfo from './ModalInfo';
import ModalInfoKiosk from './ModalInfoKiosk';
import ModalErrorLicenceplateUnknown from './ModalErrorLicenceplateUnknown';
import ModalHowToDetermineSize from './ModalHowToDetermineSize';
import ModalHowToPump from "./ModalHowToPump";
import ModalInfoIdentifySize from "./ModalInfoIdentifySize";
import ModalResultsMore from "./ModalResultsMore";
import './modal.scss';

const Modal = forwardRef((props, ref) => {
	console.log('modal', props);
	const [hidden, setHidden] = useState(true);

	let contentNode;

	const show = (modalId) => {
		switch(modalId) {
			case('info'):
				if (props.isKioskMode) ReactDOM.render(<ModalInfoKiosk />, contentNode);
				else ReactDOM.render(<ModalInfo />, contentNode);
			break;
			case ('error-licenceplate-unknown'):
				ReactDOM.render(<ModalErrorLicenceplateUnknown hide={hide} />, contentNode);
			break;
			case ('how-to-pump'):
				ReactDOM.render(<ModalHowToPump />, contentNode);
			break;
			case ('info-identify-size'):
				ReactDOM.render(<ModalInfoIdentifySize />, contentNode);
				break;
			case ('how-to-determine-size'):
				ReactDOM.render(<ModalHowToDetermineSize />, contentNode);
			break;
			case ('results-more'):
				ReactDOM.render(<ModalResultsMore />, contentNode);
				break;
			default:
			break;
		}

		setHidden(false);

		window.document.addEventListener('keyup', (event) => {
			if (event.defaultPrevented) {
				return;
			}
			var key = event.key || event.keyCode;
			if (key === 'Escape' || key === 'Esc' || key === 27) {
				hide();
			}
		});
	}

	const hide = () => {
		setHidden(true);
	}

	const onRootnodeClick = (e) => {
		if (!e.target.classList.contains('modal-cont')) {
			return false;
		}
		hide();
	};

	const onCloseButtonClick = (e) => {
		hide();
	};

	useImperativeHandle(ref, () => {
		return {
			show: show,
			hide: hide
		};
	});

	return (
		<React.Fragment>
		<div className={"modal-cont " + (hidden ? 'hidden' : '')} onClick={onRootnodeClick}>
			<div className="modal">
				<div className="btn-close" role="button" onClick={onCloseButtonClick}></div>
				<div className="content" ref={c => (contentNode = c)}>
					{}
				</div>
			</div>
		</div>
		</React.Fragment>
	)
});

export default Modal;
