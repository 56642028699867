import React from 'react';

const ModalHowToDetermineSize = () => {
	return <React.Fragment>
		<h2>Bandenmaat</h2>
		<p>De bandenmaat kunt u vinden aan de zijkant van uw autoband. Zoals aangegeven op onderstaande tekening. In dit geval 205/55/R16.</p>
		<img alt="tyre size measurement" src="/images/popup-tyresize.png" />

	</React.Fragment>
}

export default ModalHowToDetermineSize;