import React from 'react';

const ModalResultsMore = () => {
	return <React.Fragment>
		<h2>De bandenspanning is op basis van volle belading</h2>
		<p>Lang niet alle automobilisten controleren elke twee maanden hun bandenspanning. Het voordeel van de hogere adviesspanning is dat de banden over een langere periode op voldoende spanning blijven. Het rijden met een te lage bandenspanning vermindert het comfort en de wegligging van de auto, vergroot de kans op een klapband en het zorgt voor meer brandstofverbruik.</p>
		<p>Wijkt de adviesspanning van de fabrikant af van de door deze applicatie gegeven adviesspanning, hou dan de adviesspanning van de fabrikant aan. U vindt deze op de sticker op de deurstijl, aan de binnenkant van het tankklepje of in het instructieboekje van de auto.</p>
	</React.Fragment>
}

export default ModalResultsMore;