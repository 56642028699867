import React from 'react';

const ModalErrorLicenceplateUnknown = ({hide}) => {
	console.log('hide', hide);
	return <React.Fragment>
		<h2>Helaas hebben we uw bandenmaat niet gevonden in onze database</h2>
		<p>U vindt de bandenspanning op de sticker in de deurstijl aan de bestuurderszijde van het voertuig of op de binnenzijde van het benzineklepje. Mocht er geen sticker aanwezig zijn, kijk dan in het instructieboekje van de auto of raadpleeg uw bandenspecialist.</p>
		<button onClick={hide}>Opnieuw invoeren</button>
	</React.Fragment>
}

export default ModalErrorLicenceplateUnknown;