import React from 'react';
//import Select from 'react-select';
//import Select from "react-dropdown-select";
import Select from '../components/Select';
import bapi from '../services/Bapi';
import './manual-input.scss';

class ManualInput extends React.Component {
	lists = ['serie', 'year', 'model', 'type'];
	allListsLoaded = false;

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			noResults: false,
			make: this.props.makes,
			serie: [],
			model: [],
			type: [],
			year: [],
			selected: {
				make: this.props.make ? this.props.make : 0,
				serie: this.props.serie ? this.props.serie : 0,
				model: this.props.model ? this.props.model : 0,
				year: this.props.year ? this.props.year: 0,
				type: this.props.type ? this.props.type : 0
			}
		}
	}



	componentDidMount() {
		// need to check if there are already proprties retrieved from a previous search.
		if (this.props.make) {
			this.setValue('make', this.props.make, "serie", true);
		}
	}


	onCompletion = () => {
		this.setState({loading: true});
		this.props.onCompletion({ids: this.state.selected, human: {
			make: this.state.make.find((el) => {
				return el.value == this.state.selected.make;
			}),
			serie: this.state.serie.find((el) => {
				return el.value == this.state.selected.serie;
			}),
			type: this.state.type.find((el) => {
				return el.value == this.state.selected.type;
			})
		}}).then(
			(res) => {
				this.setState({loading: false});
			}
		)
	}

	isCompleted = () => {
		const selected = this.state.selected;
		if (!this.allListsLoaded) return false;
		if (selected.make != 0 && selected.serie != 0 && selected.model != 0 && selected.type != 0) {
			return true;
		}
		return false;
	}

	setValue = (field, value, nextField, prefill) => {
		let selected = this.state.selected;
		selected[field] = value;

		if (!value) { // value unset, hide next fields
			let nextFieldIndex = this.lists.indexOf(nextField);
			if (nextFieldIndex === -1) {
				return false;
			}
			const listsAfter = this.lists.slice(nextFieldIndex);
			for (let i in listsAfter) {
				this.setState({
					[listsAfter[i]]: []
				})
			}
			return false;
		}


		if (nextField) {

			this.allListsLoaded = false;
			this.setState({ loading: true });
			const nfUc = nextField.replace(/^\w/, c => c.toUpperCase());

			bapi[`get${nfUc}s`](value, this.state.selected).then(res => {

				let newState = { ...this.state };
				let nextFieldIndex = this.lists.indexOf(nextField);
				//debugger;
				if (nextFieldIndex !== -1) {
					const listsAfter = this.lists.slice(nextFieldIndex);
					for (let i in listsAfter) {
						if (!prefill) {
							selected[listsAfter[i]] = 0;
						}
						newState[listsAfter[i]] = [];
					}
				}

				newState[nextField] = res;
				newState.noResults = res.length >= 1 ? false : true;
				newState.selected = selected;
				newState.loading = false;

				//this.setState({ [nextField]: res, noResults: res.length >= 1 ? false : true, selected: selected, loading: false }, () => { });
				this.setState(newState);

				if (newState.selected[nextField] != 0) {
					this.setValue(nextField, this.state.selected[nextField], this.lists[nextFieldIndex + 1], prefill);
				} else if (res.length === 1) { // if there's only one to select from, select it and automatically load the next list
					this.setValue(nextField, res[0].value, this.lists[nextFieldIndex+1], prefill);
				}
			});

		} else {
			this.allListsLoaded = true;
			//its the last step
			this.setState({ selected: selected }, () => {
				return this.isCompleted();
			});
		}

	}
	onSubmit = () => {
		if (this.isCompleted()) {
			this.onCompletion(this.state.selected);
		}
	}

	render() {
		return <React.Fragment>
		<div className="wrapper">
			{this.state.make.length &&
				<React.Fragment>
				<section className="manual-inputs">
					{ this.props.make ?
						<React.Fragment>
							<p>Pas hieronder uw voertuig gegevens aan.<br />
								<span style={{ 'text-align': 'left', 'margin-bottom': '1em' }} class="subdued clickable" href="#" onClick={() => window.history.back()}>&lt; Ga terug naar gevonden voertuig.</span>
							</p>
						</React.Fragment>
					 :
						<p>Helaas hebben wij geen volledige match kunnen maken. Vul hieronder uw voertuig gegevens aan.</p>
					}
					<label className="car-brand">
						<h2>Automerk</h2>
						<Select
							value={this.state.selected.make}
							onChange={value => this.setValue('make', value, "serie")}
							options={this.state.make}
							isKioskMode={this.props.isKioskMode}
						/>
					</label>

					<label className="car-series" data-invisible={!this.state.serie.length}>
						<h2>Model</h2>
						<Select
							value={this.state.selected.serie}
							onChange={value => this.setValue('serie', value, "year")}
							options={this.state.serie}
							isKioskMode={this.props.isKioskMode}
						/>
					</label>
					<label className="car-years" data-invisible={!this.state.year.length}>
						<h2>Bouwjaar</h2>
						<Select
							value={this.state.selected.year}
							onChange={value => this.setValue('year', value, "model")}
							options={this.state.year}
							isKioskMode={this.props.isKioskMode}
						/>
					</label>
					<label className="car-models" data-invisible={!this.state.model.length}>
						<h2>Serie</h2>
						<Select
							value={this.state.selected.model}
							onChange={value => this.setValue('model', value, "type")}
							options={this.state.model}
							isKioskMode={this.props.isKioskMode}
						/>
					</label>
					<label className="car-types" data-hidden={!this.state.type.length}>
						<h2>Uitvoering</h2>
						<Select
							value={this.state.selected.type}
							onChange={value => this.setValue('type', value)}
							options={this.state.type}
							isKioskMode={this.props.isKioskMode}
						/>
					</label>
					<p>{this.state.noResults}</p>
					{ this.state.noResults &&
					<p>We konden geen informatie voor dit model vinden. Selecteer een ander model.</p>
					}
				</section>
				</React.Fragment>
			}
		</div>
		<div className="buttons">
			<button disabled={!this.isCompleted()} data-loading={this.state.loading} className="chunky" onClick={this.onSubmit}>Volgende</button>
		</div>
		</React.Fragment>
	}

}

export default ManualInput;