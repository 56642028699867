import React from 'react';
import './switch.scss';

const Switch = ({ onChange }) => {
	return <label className="switch">
			<input type="checkbox" defaultChecked={1} onChange={e => onChange(e.target.checked)} />
			<span className="slider round"></span>
		</label>
}

export default Switch;