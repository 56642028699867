import React from 'react';

const ModalInfoIdentifySize = () => {
	return <React.Fragment>
		<h2>Bandenmaat vaststellen</h2>
		<p>Helaas hebben we uw bandenmaat niet gevonden in onze database.</p>
		<p>U vindt de bandenspanning op de sticker in de deurstijl aan de bestuurderszijde van het voertuig of op de binnenzijde van het benzineklepje. Mocht er geen sticker aanwezig zijn, kijk dan in het instructieboekje van de auto of raadpleeg uw bandenspecialist.</p>
	</React.Fragment>
}

export default ModalInfoIdentifySize;