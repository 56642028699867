import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MatomoTracker from '@datapunt/matomo-tracker-js';
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/600.css";


window.tracker = new MatomoTracker({
	urlBase: 'https://piwik.schuttelaar.net/',
	siteId: 64,
	//userId: '1234567', // optional, default value: `undefined`.
	//trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
	//srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
	disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
	heartBeat: { // optional, enabled by default
		active: true, // optional, default value: true
		seconds: 10 // optional, default value: `15
	},
	linkTracking: true, // optional, default value: true
	configurations: { // optional, default value: {}
		// any valid matomo configuration, all below are optional
		disableCookies: true,
		setSecureCookie: false,
		setRequestMethod: 'GET'
	}
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
