import React from 'react';
import './select.scss';

const Select = ({ value, onChange, options, groups, groupsLabels, emptyLabel, isKioskMode }) => {
	return <select onChange={e => onChange(e.target.value)} value={options.length === 1 ? options[0].value : value}>
		{ (groups || options.length > 1) && <option value="0">{emptyLabel ?? emptyLabel}</option> }
		{options &&
			groups ?
			Object.keys(options).map((gindex, i) => {
				//const group = options[gindex];
				return <optgroup key={gindex} label={groupsLabels ? groupsLabels[i] : gindex}>
				{options[gindex].map(function (option, j) {
					return <option key={option.value} value={option.value}>{option.label}</option>
				})}
				</optgroup>
			}) :
			options.map(function (option, index) {
				return <option key={option.value} value={option.value}>{option.label}</option>
			})
		}
		{isKioskMode && <option></option>}
		{isKioskMode && <option></option>}
		{isKioskMode && <option></option>}

	</select>
}

export default Select;